@import "../src/assets/css/variables";
@import "../node_modules/bootstrap/scss/bootstrap";

// Custom height and width classes
@include custom-class("height", 38);
@include custom-class("width", 38);
@include custom-class("height", 60);
@include custom-class("height", 100);
@include custom-class("width", 60);
@include custom-class("width", 110);
@include custom-class("width", 130);
@include custom-class("width", 150);
@include custom-class("width", 180);
@include custom-class("width", 250);
@include custom-class("width", 300);

body {
  background-color: #f0f0f0;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.form-control.is-invalid {
  background-image: none !important;
}

.form-floating {
  margin-bottom: 20px !important;
  > .form-control,
  > .form-control-plaintext,
  > .form-select {
    height: 44px;
    min-height: 44px;
  }

  > label {
    padding: 0.6rem 0.75rem;
  }

  > .form-control,
  > .form-control-plaintext {
    &:focus {
      padding-top: 0.625rem;
    }

    &:not(:placeholder-shown) {
      padding-top: 0.625rem;
    }
  }

  > .form-control:focus ~ label,
  > .form-control:not(:placeholder-shown) ~ label,
  > .form-control-plaintext ~ label,
  > .form-select ~ label {
    transform: scale(0.85) translateY(-1.4rem) translateX(0.15rem);
  }
}

.custom-min-width-100 {
  min-width: 100px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

.theme-card-header {
  h5 {
    padding-left: 70px;

    .theme-icon-box {
      top: -36px;

      .theme-icon-box-inner {
        width: 35px;
        height: 24px;

        svg {
          width: auto;
          height: 100%;
        }
      }
    }
  }
}

.table > thead {
  vertical-align: middle;
}

tbody tr {
  vertical-align: middle;
}

.form-check-input:checked {
  background-color: #4dae35;
  border-color: #4dae35;
}

.react-select-container {
  .react-select__control {
    height: 44px;
  }

  + label {
    background: #fff;
    padding: 8px 3px;
    z-index: 999;
    margin: 1px 5px;
    height: 37px;
  }
}

.hideInSmallSidebar {
  font-size: 13px;
  font-weight: 600;
}

.table-bordered th {
  background: #f2f2f2;
}

.pagination .page-item .page-link {
  border-radius: 100%;
  font-size: 14px;
  color: #000;
  border: none;
  margin: 0px 3px;
}

.pagination .page-item.active .page-link {
  background: rgba(163, 164, 177, 0.25);
}

.pagination .page-item.prev-item .page-link {
  background: none;
}

.react-select__control:focus {
  box-shadow: none;
}

.total_new_users {
  background-color: #ccdee7;
  color: #4384a5;
}

.new_users_trial {
  background-color: #fff4d8;
  color: #ffc73b;
}

.new_users_paid {
  background-color: #dbefd7;
  color: #80c56f;
}

.new_users_free {
  background-color: #fedbdb;
  color: #fb5353;
}

.registered_users {
  background-color: #fbe8d6;
  color: #ec8c34;
}

.active_users {
  background-color: #dee7ff;
  color: #5b89ff;
}

.revenue {
  background-color: #fedbf7;
  color: #fa85e2;
}

.customer_churn_rate {
  background-color: #eee8ff;
  color: #9771ff;
}

.current_month-pack {
  background: #f849d5;
  padding: 5px 10px;
  color: #fff;
  font-size: 10px;
  border-radius: 25px;
}

.on_trail {
  background: #ffc73b;
  padding: 5px 10px;
  color: #000;
  font-size: 10px;
  border-radius: 25px;
  font-weight: 600;
}

.paid {
  background: #4dae35;
  padding: 5px 10px;
  color: #fff;
  font-size: 10px;
  border-radius: 25px;
}

.free {
  background: #fb4d4d;
  padding: 5px 10px;
  color: #fff;
  font-size: 10px;
  border-radius: 25px;
}

.on_trail_box {
  width: 21px !important;
  height: 12px !important;
  background: #ffc73b;
  border-radius: 2px;
  display: inline-block;
  margin-right: 10px;
}

.paid_box {
  width: 21px !important;
  height: 12px !important;
  background: #4dae35;
  border-radius: 2px;
  display: inline-block;
  margin-right: 10px;
}

.free_box {
  width: 21px !important;
  height: 12px !important;
  background: #fb4d4d;
  border-radius: 2px;
  display: inline-block;
  margin-right: 10px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.goal-modal-cover .modal-title {
  font-size: 16px;
  color: #000;
  font-weight: 600;
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
// .form-floating>.form-control-plaintext~label,
.form-floating>.form-select~label {
  transform: scale(0.625) translateY(-1.4rem) translateX(0.15rem);
  color: #000;
  font-weight: 600;
}

.goal-modal-cover {
  border-radius: 16px;
}

.card-footer {
  background-color: #fff !important;
}

.student-details-cover {
  background-color: #f1f1f1;
  padding: 16px;
  border-radius: 8px;

  p {
    margin-bottom: 0;
    font-size: 13px;
  }

  label {
    font-size: 13px;
    font-weight: 600;
  }
}

.status-radio {
  input {
    margin-right: 10px;
  }

  .form-check-input:checked {
    background-color: #fff;
    border-color: #2f61a8;
    background-size: 16px;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%232F61A8%27/%3e%3c/svg%3e");
  }
}

.form-control.filled {
  border-color: #000 !important;
}

.form-control:disabled + label,
.form-control:disabled {
  color: #aeaeae !important;
}

.mw-44 {
  min-height: 44px;
}

.form-control__calendar {
  span {
    svg {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-50%, 50%);
    }
  }
}

.react-select-menu {
  z-index: 999;
}

.w-20 {
  width: 20%;
}

.form-check-input:focus,
.page-link:focus {
  box-shadow: none;
}

.ql-editor {
  overflow-y: inherit !important;
  overflow: auto;
  height: 260px;
}


.text-area-cover {
  height: 88px !important;
  resize: none;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #505050;
}

.mw-1 {
  min-width: 1px;
}

.swal2-confirm.swal2-styled {
  background-color: #ffc73b;
  color: #000000;
  font-weight: 600;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    background: #ffcf58;
  }
}


.swal2-cancel.swal2-styled {
 
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #000000;
  font-weight: 600;
  height: 43px;

  &:hover {
    display: inline-block;
    background: #000;
    color: #fff;
  }

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }
}



.filterby {
  width: 200px;
}

.filterby .form-floating {
  margin-bottom: 0px !important;
}

@media screen and (max-width: 767px) {
  .mobile-100-width {
    width: 100%;
    margin-top: 15px;
  }

  .theme-card-cover {
    height: inherit !important;
  }

  .form-floating {
    margin-bottom: 20px !important;
  }

  .custom-width-130 {
    width: 100%;
  }
}

.student-details-cover img {
  max-width: 123px;
  max-height: 77px;
}

.goal-image {
  width: 100%;
  height: 120px;

  background-color: #ececec;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ececec;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
}

body:has(.cms-page) {
  color: white;
  background-color: #20222c;
}

.dashboard-revenue {
  .react-datepicker-wrapper {
    height: 16px;
    width: 16px;
    position: absolute;
  }
}
.edit-icon{
  position: absolute;
  width: 30px;
  bottom: -13px;
  left: 0;
  right: 0;
  margin: 0 auto;
  cursor: pointer;
}

.z-999{
  z-index: 999 !important;
}