.password-icon {
  position: absolute;
  right: 14px;
  top: 14px;
  cursor: pointer;
  color: #d9d9d9;
  font-size: 16px;
  z-index: 1;
}

.type-password {
  input {
    padding-right: 2.25rem !important;
  }
}

.rtl-label {
  right: 0.5rem !important;
  left: auto !important;
}

.form-label-group.in-border input:focus ~ .rtl-label,
.form-label-group.in-border input:not(:placeholder-shown) ~ .rtl-label,
.form-label-group.in-border select ~ .rtl-label {
  right: calc(var(--input-padding-x) - 40px) !important;
  left: auto !important;
}

.form-label-group.in-border textarea:focus ~ .rtl-label,
.form-label-group.in-border textarea:not(:placeholder-shown) ~ .rtl-label {
  height: auto;
  right: calc(var(--input-padding-x) - 40px) !important;
  min-height: 100px !important;
}
