.react-select {
  // Styles for the container element

  &__control {
    // Styles for the control element
    border: 1px solid #a0a0a0 !important;

    border-radius: 0.375rem !important;
    box-shadow: none !important;

    &--is-focused {
      border-color: #505050 !important;
      box-shadow: none;
    }

    &__value-container {
      // Styles for the value container element
    }

    &__indicators {
      // Styles for the indicators element
    }
  }

  &__menu {
    // Styles for the menu element

    border-radius: 0 !important;
    margin-top: 3px !important;
    z-index: 9999 !important;

    &-list {
      // Styles for the menu list element
      padding: 0 !important;
      border-radius: 0 !important;
      font-weight: 600 !important;
    }
  }

  &__option {
    padding: 6px 12px !important;
    // Styles for the option element

    &--is-focused {
      //   background-color: red !important;
      background-color: #ffc73baa !important;
    }

    &--is-selected {
      //      background-color: red !important;
      background-color: #ffc73b !important;
    }
  }
}

.form-floating {
  position: relative;
}

.form-floating label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 0.75rem 0.75rem;
  pointer-events: none;
  transform-origin: 0 0;
  transition: all 0.1s ease-in-out;
}

.form-floating .react-select-container.has-value + label,
.form-floating .react-select-container:focus + label {
  transform: scale(0.75) translateY(-1.25rem);
  font-weight: 600;
}

.react-select-container {
  position: relative;
  z-index: inherit;
}
