.reset-password {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;

  .inner-box {
    padding: 32px 30px 37px 30px;
    border-radius: 8px;
  }

  label {
    font-size: 14px;
    font-weight: 600;
    color: #333;
    margin: 0;
    user-select: none;
  }
}
